<template>
    <main class="main mt-8">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 order-lg-last dashboard-content">
                    <div class="col-sm-12">
                    </div>
                    <div class="col-sm-12">
                        <form class="mt-2" @submit.prevent="gift_card">
                            <div class="form-group">
                                <label for="v-card-number" style="font-size:xx-large; font-weight:bold;">Coupon</label>
                                <input type="text" class="form-control" v-model="valueFields.cardNumber" placeholder="Code">
                                <p v-if="error" class="text-danger">{{ this.error }}</p>
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
                <!-- End .col-lg-4 -->

                <aside class="sidebar col-lg-2">
                    <div class="widget widget-dashboard">
                        <h3 class="widget-title">الحساب</h3>

                        <ul class="list">
                            <li ><a href="/account">معلومات الحساب</a></li>
                            <li><a href="/orders">الطلبيات</a></li>
                            <li class="active"><a @click.prevent>المحفظة</a></li>
                            <li><a href="/addresses">العناوين</a></li>
                        </ul>
                    </div>
                    <!-- End .widget -->
                </aside>
                <!-- End .col-lg-3 -->
                <div class="col-lg-5 DivWithScroll mb-8 mr-5">
                    <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Action</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody id="wallet-tbody">
                        <tr v-for="item in wallet" :key="item.id">
                            <th scope="row">{{ item.transactionId }}</th>
                            <td v-if="item.walletAction == 'DEPOSIT'">
                                <p class="deposit">{{ item.walletAction }}<i class="ml-5 fas fa-long-arrow-alt-up"></i></p>
                            </td>
                            <td v-else>
                                <p class="coupon">{{ item.walletAction }}<i class="ml-2 fas fa-long-arrow-alt-down"></i></p>
                            </td>
                            <td>{{ item.amount }}</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <!-- End .row -->
        </div>
        <!-- End .container -->

    </main>
</template>

<script>


export default {
    data() {
        return {
            wallet: [],
            labels: {
                cardName: 0,
                cardHolder: '',
                cardExpires: ' '
            },
            valueFields: {
                cardName: 0,
                cardNumber: '',
                cardMonth: new Date().getMonth() + 1,
                cardYear: new Date().getFullYear(),
            },
            error: '',
            error_counter: 0,
        }
    },
    mounted() {
        this.labels.cardHolder = localStorage.getItem('username')
        this.axios({
            url: 
process.env.VUE_APP_URL+'/api/customer-wallets?sort=lastModifiedDate,desc',
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            response.data.forEach(element => {
                this.labels.cardName += element.amount
            });
            this.wallet = response.data
            if(this.labels.cardName === 0) {
                this.labels.cardName = '0'
            }
        })
    },
    methods: {
        gift_card: function() {
            this.axios({
                url: process.env.VUE_APP_URL+`/api/gift-cards/redeem/${this.valueFields.cardNumber}`,
                method: 'GET',
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
            .then(() => {this.$router.go()})
            .catch(() => {
                this.error_counter += 1
                this.error = `(${this.error_counter}) الكود غير صحيح`
            })
        }
    }
}
</script>

<style scoped>
    img.card-item__typeImg {
        display: none !important;
    }

    .DivWithScroll{
        height:450px;
        overflow:scroll;
        overflow-x:hidden;
    }

    p {
        font-size: medium;
        font-weight: 500;
    }

    .coupon {
        color: red;
    }

    .deposit {
        color: green;
    }

    thead {
    position: sticky;
    top: 0;
    background: #bfc0c0d4;
}
</style>